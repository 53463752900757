import React from "react"
import Layout from "../components/common/layout"
import { graphql } from "gatsby"
import murrineStyles from "../styles/pages/murrine.module.scss"
import MurrinaCard from "../components/cards/murrinaCard"

const Oggettistica = ({ data, location }) => {
  const murrine = data.allStrapiMurrine.edges || []

  return (
    <Layout
      pageMeta={{
        title: `Oggettistica`,
        keywords: [`oggettistica`, `La Murrina`, `Murano Glass`, `Antica Murrina Venezia`, `MurrinaMurano`],
        description: `Oggettistica in vetro di Murano di La Murrina, Murano Glass, Antica Murrina Venezia e MurrinaMurano`,
        link: `/oggettistica`,
      }}
      location={location}
    >
      <ul className={murrineStyles.murrineAltro}>
        {murrine.length ? (
          murrine.map(murrina => (
              <MurrinaCard  key={murrina.node.strapiId} murrina={murrina} />
          ))
        ) : (
          <p className={murrineStyles.text}>
            non ci sono prodotti in questa categoria
          </p>
        )}
      </ul>
    </Layout>
  )
}
export default Oggettistica

export const query = graphql`
  query OggettisticaQuery {
    allStrapiMurrine(filter: {categoria: {eq: "oggettistica"}}) {
      edges {
        node {
          strapiId
          slug
          nome
          categoria
          prezzo
          promo {
            codice
            inizio
            fine
          }
          marchio {
            nome
            logo {
              alternativeText
              hash
              width
              height
              url
            }
          }
          variante_colore {
            colore
            quantita
            foto {
              alternativeText
              hash
              width
              height
              url
            }
          }
        }
      }
    }
  }
`